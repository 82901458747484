import React from "react"
import { Helmet } from "react-helmet"

const Head = (props) => {
    return (
        <Helmet title={`Ali Osborn | ${props.title}`}>
            {/* icons go here */}
        </Helmet>
    )
}

export default Head