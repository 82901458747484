import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import colors from "../styles/colors"
import measurements from "../styles/measurements"

const NavWrapper = styled.nav`
  width: ${measurements.navWidth}rem;
  position: fixed;
  top: ${measurements.layoutPadding}rem;
  left: ${measurements.layoutPadding}rem;
  z-index: 1;
  overflow-x: hidden;

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - ${measurements.layoutPadding}rem);
  }
`

const NavLink = styled(props => <Link to={props.to} {...props} />)`
  display: block;
  font-size: 2rem;
  text-decoration: none;
  padding-bottom: 1rem;
  color: ${colors.grey};
  transition: 0.2s ease all;

  :hover {
    color: ${colors.black};
  }

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    margin-right: 2rem;

    &:first-of-type {
      font-size: 2.8rem;
      width: 100%;
    }
  }
`

const activeLink = {
  color: `#000`,
  fontWeight: `500`,
}

const Nav = () => {
  return (
    <NavWrapper>
      <NavLink to="/" activeStyle={activeLink}>
        ALI OSBORN
      </NavLink>
      <NavLink to="/drawings" activeStyle={activeLink} partiallyActive={true}>
        DRAWINGS
      </NavLink>
      <NavLink to="/prints" activeStyle={activeLink} partiallyActive={true}>
        PRINTS
      </NavLink>
      <NavLink to="/objects" activeStyle={activeLink} partiallyActive={true}>
        OBJECTS
      </NavLink>
      <NavLink to="/cv" activeStyle={activeLink}>
        CV
      </NavLink>
      <NavLink to="/contact" activeStyle={activeLink}>
        CONTACT
      </NavLink>
    </NavWrapper>
  )
}

export default Nav
