import React from "react"
import styled from "styled-components"

// eslint-disable-next-line
import styles from "../styles/global.css"
import measurements from "../styles/measurements"

import Head from "./Head"
import Nav from "./Nav"

const LayoutWrapper = styled.div`
  padding: ${measurements.layoutPadding}rem;

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    padding: ${measurements.layoutPadding / 2}rem;
  }
`

const Content = styled.div`
  width: calc(100% - ${measurements.navWidth}rem);
  // height: 100vh;
  margin-left: ${measurements.navWidth}rem;

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }
`

const Layout = props => {
  return (
    <LayoutWrapper>
      <Head title={props.title}></Head>
      <Nav></Nav>
      <Content>{props.children}</Content>
    </LayoutWrapper>
  )
}

export default Layout
